
import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import './assets/css/common.css'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import VueDaumPostcode from 'vue-daum-postcode';
import store from "@/stores/store";
import filters from "@/plugin/filters"

import moment from "moment";
import http from "@/plugin/http"


const app = createApp(App)

app.use(store);
app.use(router);
app.use(VueDaumPostcode);
app.component('VueDatePicker', VueDatePicker);

app.config.globalProperties.$axios = http;
app.config.globalProperties.$store = store;
app.config.globalProperties.$filters = filters;

store.$axios = http;

app.use(moment)
app.mount('#app')