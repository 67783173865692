<template>
  <header>
    <div class="gnb">
      <div class="wrapper">
        <RouterLink to="/login" v-if="!store.getters.getMyInfo">로그인</RouterLink>
        <span v-if="store.getters.getMyInfo"><b>{{ store.getters.getMyInfo.brandName }}</b> 님</span>
        <a class="ico_logout" v-if="store.getters.getMyInfo" @click.prevent="logOut()"> 로그아웃</a>
        <RouterLink to="/signup" v-if="!store.getters.getMyInfo">회원가입</RouterLink>
        <RouterLink to="/notice">고객센터</RouterLink>
        <RouterLink to="/entry">입점신청</RouterLink>
      </div>
    </div>

    <div class="header_search">
      <RouterLink to="/">
        <h1 class="logo">모든수산</h1>
      </RouterLink>
      <div class="search">
        <input type="search" v-model="keyword" @keydown.enter="searchKeyword()" />
        <button class="btn_search" @click="searchKeyword()">검색</button>
      </div>
      <div class="my_menu">
        <button class="ico_mypage" @click="goMypage()">마이페이지</button>
        <button class="ico_cart" @click="goCart()"><i class="badge" v-if="store.getters.getCart > 0">{{
            store.getters.getCart }}</i></button>
      </div>
    </div>
    <div class="category_bar">
      <div class="wrapper">
        <div class="btn_category">카테고리
          <div class="category_list">
            <ul>
              <li v-for="(subCategories, mainCategory) in store.getters.getCategoryList" :key="mainCategory" class="btn_depth1">
                <button>{{ mainCategory }}</button>
                <ul class="depth2_list">
                  <li v-for="subCategory in subCategories" :key="subCategory">
                    <button @click="router.push({ name: 'product', query: { enterType: 'category', category:subCategory, mainCategory:mainCategory } })">
                      {{ stripNumbers(subCategory)  }}
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <RouterLink :to="'/product?enterType=exhibition&exhibition=' + exhibition.id" class="menu_special"
                    v-for="exhibition in store.getters.getExhibitionList.filter(e => e.menuExposure === 'Y' && e.useYn === 'Y')">
          {{ exhibition.title }}
        </RouterLink>
        <!-- <button class="guide">이용안내</button> -->
      </div>
    </div>
  </header>
</template>..

<script setup>
import { computed, getCurrentInstance, onMounted, ref, watch } from "vue"
import { useRoute, useRouter } from 'vue-router'
import { useStore } from "vuex"

const { proxy } = getCurrentInstance()
const router = useRouter()
const route = useRoute()

const currentRouter = ref(router)
const store = useStore()
const login = ref(false)
const keyword = ref('')
function searchKeyword() {
  if(keyword.value == null || keyword.value?.trim() == '') {
    alert("검색할 키워드를 입력해 주세요.")
    return;
  }
  router.push({
    path: '/product',
    query: {
      enterType: 'search',
      keyword: keyword.value
    }
  })
}

function stripNumbers(subCategoryName) {
  return subCategoryName.replace(/^\d+/, '');
}

function logOut() {
  store.dispatch('initState')
  console.log(route.meta)
  if (route.meta.needAuth) {
    router.push('/')
  } else {
    router.go(0)
  }

}


async function goMypage() {
  if (!store.getters.getMyInfo) {
    // alert("로그인이 필요합니다.\n로그인페이지로 이동합니다.")
    let loginNext = {
      nextPath: '/cart',
      nextQuery: null
    }
    store.commit({ type: 'setLoginNext', loginNext: loginNext })
    await router.push({ name: 'login' })
    return
  }
  router.push({ path: '/order' })
}

async function goCart() {
  if (!store.getters.getMyInfo) {
    // alert("로그인이 필요합니다.\n로그인페이지로 이동합니다.")
    let loginNext = {
      nextPath: '/cart',
      nextQuery: null
    }
    store.commit({ type: 'setLoginNext', loginNext: loginNext })
    await router.push({ name: 'login' })
    return
  }
  await router.push({ path: '/cart' })
}


async function exhibition() {
  await proxy.$axios.get('/api/common/exhibition')
      .then(response => {
        if (response.data.resultCode === 0) {
          // exhibitionList.value = response.data.result
          store.commit("setExhibitionList", { exhibitionList: response.data.result })
        } else {
          alert(response.data.resultMessage)
        }
      })
      .catch(error => {
        if (error.response?.data?.resultMessage) {
          alert(error.response?.data?.resultMessage)
        } else {
          alert("기획전을 불러오는 도중 오류가 발생했습니다.")
        }
      })
}

function getCategoryList() {
  proxy.$axios.get('/api/common/item/category/v2/mainCategory')
      .then(response => {
        if (response.data.resultCode === 0) {
          store.commit('setCategoryList', response.data.result[0])

        } else {
          alert(response.data.resultMessage)
        }
      }).catch(error => {
    if (error.response?.data?.resultMessage) {
      alert(error.response?.data?.resultMessage)
    } else {
      alert("카테고리를 불러오는 도중 오류가 발생했습니다.")
    }
  })

}

watch(route, async () => {
  // if(store.getters.getCategoryList.length==0) {
  await getCategoryList()
  // }
}, { immediate: true })

watch(store.getters.getSessionToken, (newValue) => {
  if (newValue) {
    login.value = true
    exhibition()
    if (!store.getters.getMyInfo) {
      console.log("내정보 불러오기")
    }
  }
}, { immediate: true, deep: true })

onMounted(() => {
  keyword.value = route.query.keyword
  exhibition()
})

</script>


<style>
html, input, textarea, button{
  font-family: "Pretendard", sans-serif;
}
</style>
