import {
  createRouter,
  createWebHistory
} from 'vue-router'
import fullLayout from '../views/common/fullLayout.vue'
import store from "@/stores/store"

const routes = [{
    path: '/',
    name: 'Intro',
    redirect: {
      name: 'main'
    },
    meta: {
      title: '모든수산 공급업체'
    }
  },
  {
    path: '/',
    component: fullLayout,
    children: [{
        path: '/',
        component: () => import('@/views/Main'),
        name: 'main',
      }, {
        path: '/login',
        component: () => import('@/views/signup/Login'),
        name: 'login',
      }, {
        path: '/find_id',
        component: () => import('@/views/signup/findId'),
        name: 'find id',
      }, {
        path: '/find_id_done',
        component: () => import('@/views/signup/findIdDone'),
        name: 'find id done',
      }, {
        path: '/find_pw',
        component: () => import('@/views/signup/findPw'),
        name: 'find pw',
      }, {
        path: '/reset_pw',
        component: () => import('@/views/signup/resetPw'),
        name: 'reset pw',
      }, {
        path: '/signup',
        component: () => import('@/views/signup/signUp'),
        name: 'signup',
      }, {
        path: '/signup_reg',
        component: () => import('@/views/signup/signUpRegister'),
        name: 'signup register',
      }, {
        path: '/product',
        component: () => import('@/views/product/product'),
        name: 'product',
      }, {
        path: '/product_detail',
        component: () => import('@/views/product/productDetail'),
        name: 'product detail',
      }, {
        path: '/notice',
        component: () => import('@/views/support/notice'),
        name: 'notice',
      }, {
        path: '/notice_detail',
        component: () => import('@/views/support/noticeDetail'),
        name: 'notice view',
      },
      {
        path: '/faq',
        component: () => import('@/views/support/faq'),
        name: 'faq',
      }, {
        path: '/order',
        component: () => import('@/views/mypage/orderList'),
        meta:{
          needAuth:true
        },
        name: 'order',
      }, {
        path: '/order_detail',
        component: () => import('@/views/mypage/orderDetail'),
        meta:{
          needAuth:true
        },
        name: 'order detail',
      }, {
        path: '/address',
        component: () => import('@/views/mypage/address'),
        meta:{
          needAuth:true
        },
        name: 'address',
      }, {
        path: '/privacy_modify',
        component: () => import('@/views/mypage/privacyModify'),
        meta:{
          needAuth:true
        },
        name: 'privacy_modify',
      }, {
        path: '/entry',
        component: () => import('@/views/signup/entry'),
        name: 'entry',
      }, {
        path: '/cart',
        component: () => import('@/views/cart/cartList'),
        meta:{
          needAuth:true
        },
        name: 'cart',
      },
      {
        path: '/purchase',
        component: () => import('@/views/Purchase'),
        meta:{
          needAuth:true
        },
        name: 'purchase',
      },{
        path: '/purchase_done',
        component: () => import('@/views/PurchaseDone'),
        name: 'purchase done',
      },
      {
        path: "/:pathMatch(.*)*",
        component: () => import('@/views/NotFound.vue'),
        name: "NotFound",
      },

    ]
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.beforeEach((to, from, next)=>{
  if(to.meta.needAuth) {
    // if(store.) 로그인 상태인지 확인 
    //아니면 로그인 화면으로 튕기기 
    if (!store.getters.getMyInfo) {
      alert("로그인이 필요합니다.\n로그인페이지로 이동합니다.")
      let loginNext = {
        nextPath: to.path,
        nextQuery: to.query
      }
      store.commit({type: 'setLoginNext', loginNext: loginNext}); 
      router.push({name: 'login'});
    }
  }
  next()
  document.body.scrollTo(0,0)
})

export default router;